export const content = {
    tamil: {
        home: 'Home',
        cookbook: "சமையல் புத்தகம்",
        products: 'Products',
        vision: "தூதர்",
        about: "பற்றி",
        contacts: "தொடர்பு",
        feedback: "Feedback",
        ambassador: "Ambassadors"
    },
    english: {
        home: 'Home',
        products: 'Products',
        vision: 'Our Vision',
        about: "About",
        cookbook: "Cook Book",
        feedback: "Feedback",
        ambassador: "Ambassadors",
        contacts: "Contact"
    }
}
export default content;