// extracted by mini-css-extract-plugin
export const main = "footer-module--main--3oOit";
export const link = "footer-module--link--36SmG";
export const container = "footer-module--container--29F0B";
export const scroll = "footer-module--scroll--29Mfn";
export const scroller = "footer-module--scroller--ONbir";
export const content = "footer-module--content--22blP";
export const packet = "footer-module--packet--LIPvm";
export const bottom = "footer-module--bottom--3nrj1";
export const map = "footer-module--map--Mc6oQ";
export const left = "footer-module--left--3xK90";
export const about = "footer-module--about--1xvk7";
export const social = "footer-module--social--15Jt1";
export const socialIcon = "footer-module--socialIcon--1fWI1";
export const socialcont = "footer-module--socialcont--2t4an";
export const insta = "footer-module--insta--3MJTi";
export const fb = "footer-module--fb--IAcvn";
export const yt = "footer-module--yt--SSUaW";
export const twitter = "footer-module--twitter--1twir";
export const whatsapp = "footer-module--whatsapp--M9GfO";