// Core
import React, {useContext, useRef } from 'react';
// Styles
import * as Styles from '../styles/footer.module.scss';
// Components
// import {Links} from "../components/header";
// Contexts
import {LanguageContext} from '../contexts/language';
// import NavContext from '../contexts/navigation';
// Contents
import content from '../contents/address';
import {aboutContent} from '../contents/about';
// External
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import pin from '../images/address.png';
import phone from '../images/phone.png';
import mail from '../images/mail.png';
// import logo from '../images/logo-full.png';

// import blue from '../images/batter house Blue.png';
// import brown from '../images/batter house brown.png';
// import green from '../images/batter house green.png';

import {Link} from 'gatsby';

const Footer = () => {
    
    // Language
    const lang = useContext(LanguageContext);
    const contents = lang.language ? content.tamil : content.english;
    const aboutContents = lang.language ? aboutContent.tamil : aboutContent.english;

    // Navigation
    const section = useRef();
    const footer = useRef();
    // const nav = useContext(NavContext);
    // useEffect(() => {
    //     var map = document.getElementById('map');
    //     map.width = window.innerWidth;
    // },[]);

    
    
    return (
    <>
        <div className={Styles.container} id="footer" ref={footer}>
            <div className={Styles.scroll}>
                <div className={Styles.content}>
                    <img src={pin} alt="Address" height="20px" width="15px"/>
                    <p>
                    {contents.address.map((line) => (
                        <>{line}</>
                    ))}
                    </p>
                </div>
                <div className={Styles.content}>
                    <img src={phone} alt="phone" width="25px" height="20px"/>
                    <p>{contents.mobile}</p>
                </div>
                <div className={Styles.content}>
                    <img src={mail} alt="mail" width="30px" height="20px"/>
                    <p>{contents.mail}</p>
                </div>
                
            </div>
        </div>
        <div className={Styles.main} ref={section} id="contacts">
            <div className={Styles.left}>

                <div className={Styles.about}>
                    <h3>About Us</h3>
                    <p>{aboutContents.p[0]}</p>
                </div>

                <div className={Styles.bottom}>
                    <h3>Site Map</h3>
                    <div>
                        <li><Link to="/#home">Home</Link></li>
                        <li><Link to="/#products">Products</Link></li>
                        <li><Link to="/vision">Our Vision</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/feedback">Feedback</Link></li>
                        <li><Link to="/#ambassador">Ambassador</Link></li>
                    </div>
                </div>

                <div className={Styles.map}>
                    <iframe
                    title="map"
                    height="300"
                    id="map"
                    style={{border:0}}
                    loading="lazy"
                    allowFullScreen
                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBs8EioAiI9KwsznUcxgDrtEqUCSCC_MPk&q=Space+Needle,Seattle+WA">
                    </iframe>
                </div> 

            </div>

            <div className={Styles.socialcont}>
                <div className={Styles.social}>
                    <a href="https://www.instagram.com/BatterHouseIndia" className={Styles.insta} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
                    <a href="https://www.facebook.com/BatterHouseIndia" className={Styles.fb} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook}/></a>
                    <a href="https://www.twitter.com/BatterHouse" className={Styles.twitter} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter}/></a>
                    <a href="https://wa.me/919344482696" className={Styles.whatsapp} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faWhatsapp}/></a>
                </div>
            </div>
            
        </div>
            
        
        
    </>
    );
}

export default Footer;

{/* <div className={Styles.content}>
    <img src={blue} className={Styles.packet}/>
    <p>Rice Batter</p>
</div>
<div className={Styles.content}>
    <img src={brown} className={Styles.packet}/>
    <p>Millets Batter</p>
</div>
<div className={Styles.content}>
    <img src={green} className={Styles.packet}/>
    <p>Herbs and Greens</p>
</div> */}
