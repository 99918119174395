// Core
import React, {useEffect, useRef, useState, useContext} from "react";

// Styles
import * as Styles from "../styles/header.module.scss";
import {CSSTransition} from "react-transition-group";

import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";
// Contexts
import {LanguageContext} from '../contexts/language';

// Contents
import content from '../contents/links';

import Logo from '../images/logo.svg';
import Ham from '../images/ham.svg';
import Hamx from '../images/hamx.svg';

import blue from '../images/batter house Blue.png';
import brown from '../images/batter house brown.png';
import green from '../images/batter house green.png';

import {Link} from "gatsby";

const homer = e => {
  window.scrollTo(0,0);
}

export const ProductsNav = ({openProducts, onMouseOut, open}) => {
  return(
    <div
      className={Styles.productsNav}
      onMouseLeave={onMouseOut}
      onMouseOver={openProducts}
      onFocus={openProducts}
      style={{
        opacity: open ? 1 : 0,
        display: open ? 'flex' : 'none',
        transition: 'opacity 0.3s ease'
      }}>
      <div className={Styles.topic}>
        <div>
          <h3>Special Batter</h3>
          <img src={blue} alt="Batter House Blue pouch" height="auto" width={200}/>
        </div>
        <ul>
          <li><Link to="#products">Pesarattu Pack</Link></li>
          <li><Link to="#products">Kaara Dosai Pack</Link></li>
          <li><Link to="#products">Aappam Pack</Link></li>
          <li><Link to="#products">Paruppu Adai Pack</Link></li>
          <li><Link to="#products">Kuzhi Paniyaram Pack</Link></li>
        </ul>
      </div>
      <div className={Styles.topic}>
        <div>
          <h3>Millets Batter</h3>
          <img src={brown} alt="Batter House Brown pouch" height="auto" width={200}/>
        </div>
        <ul>
          <li><Link to="#kambu">Kambu Pack</Link></li>
          <li><Link to="#ragi">Ragi Pack</Link></li>
          <li><Link to="#thinai">Thinai Pack</Link></li>
          <li><Link to="#kuthiraivali">Kuthiraivali Pack</Link></li>
          <li><a href="#cholam">Cholam Pack</a></li>
        </ul>
      </div>
      <div className={Styles.topic}>
        <div>
          <h3>Herbs and Greens</h3>
          <img src={green} alt="Batter House Green pouch" height="auto" width={200}/>
        </div>
        <ul>
          <li><Link to="#vallarai">Vallarai Keerai Pack</Link></li>
          <li><Link to="#mudakathan">Mudakathan Pack</Link></li>
          <li><Link to="#thoothuvalai">Thoothuvalai Pack</Link></li>
          <li><Link to="#products">Kovai Keerai Pack</Link></li>
          <li><Link to="#moringa">Moringa Pack</Link></li>
        </ul>
      </div>
    </div>
  );
}


const ProductsSide = ({open, setSidebar}) => {
  return(
    <CSSTransition
      in={open? true : false}
      timeout={300}
      classNames={{
        enter: Styles.prodSideEnter,
        enterActive: Styles.prodActive,
        exit: Styles.prodSideExit,
        exitDone: Styles.prodDone
      }}
      unmountOnExit
      onEnter={() => console.log("Prod Side")}
      onExit={() => console.log("PRoe exit")}>
    <div className={Styles.productsSide}>
      <div className={Styles.topicside}>
        <div>
          <h3>Special Batter</h3>
          <img src={blue} alt="Batter House Blue pouch" height="auto" width={200}/>
        </div>
        <ul>
          <li onClick={() => setSidebar(false)}><Link to="#products">Pesarattu Pack</Link></li>
          <li onClick={() => setSidebar(false)}><Link to="#products">Kaara Dosai Pack</Link></li>
          <li onClick={() => setSidebar(false)}><Link to="#products">Aappam Pack</Link></li>
          <li onClick={() => setSidebar(false)}><Link to="#products">Paruppu Adai Pack</Link></li>
          <li onClick={() => setSidebar(false)}><Link to="#products">Kuzhi Paniyaram Pack</Link></li>
        </ul>
      </div>
      <div className={Styles.topicside}>
        <div>
          <h3>Millets Batter</h3>
          <img src={brown} alt="Batter House Brown pouch" height="auto" width={200}/>
        </div>
        <ul>
          <li onClick={() => setSidebar(false)}><Link to="#kambu">Kambu Pack</Link></li>
          <li onClick={() => setSidebar(false)}><Link to="#ragi">Ragi Pack</Link></li>
          <li onClick={() => setSidebar(false)}><Link to="#thinai">Thinai Pack</Link></li>
          <li onClick={() => setSidebar(false)}><Link to="#kuthiraivali">Kuthiraivali Pack</Link></li>
          <li onClick={() => setSidebar(false)}><a href="#cholam">Cholam Pack</a></li>
        </ul>
      </div>
      <div className={Styles.topicside}>
        <div>
          <h3>Herbs and Greens</h3>
          <img src={green} alt="Batter House Green pouch" height="auto" width={200}/>
        </div>
        <ul>
          <li onClick={() => setSidebar(false)}><Link to="#vallarai">Vallarai Keerai Pack</Link></li>
          <li onClick={() => setSidebar(false)}><Link to="#mudakathan">Mudakathan Pack</Link></li>
          <li onClick={() => setSidebar(false)}><Link to="#thoothuvalai">Thoothuvalai Pack</Link></li>
          <li onClick={() => setSidebar(false)}><Link to="#products">Kovai Keerai Pack</Link></li>
          <li onClick={() => setSidebar(false)}><Link to="#moringa">Moringa Pack</Link></li>
        </ul>
      </div>
    </div>
    </CSSTransition>
  );
}

export const Links = ({side}) => {
  // Contexts
  const lang = useContext(LanguageContext);
  const contents = lang.language ? content.tamil : content.english;
  return(
    <div className={Styles.linksSep} >
        <li><Link to="/" onClick={homer}>{contents.home}</Link></li>
        <li><Link to="#products">{contents.products}</Link></li>
        <li><Link to="#vision">{contents.vision}</Link></li>
        <li><Link to="#about">{contents.about}</Link></li>
        <li><Link to="#cookbook">{contents.cookbook}</Link></li>
        <li><Link to="/feedback">{contents.feedback}</Link></li>
        <li><Link to="/newAmbassador">{contents.ambassador}</Link></li>
        <li><Link to="#contacts">{contents.contacts}</Link></li>
    </div>
  );
}

const Header = () => {

  // Refs
  const header = useRef();
  const logo = useRef();

  // State
  const [sidebar, setSidebar] = useState(false);

  // Contexts
  const lang = useContext(LanguageContext);
  const contents = lang.language ? content.tamil : content.english;

  // Navbar
  let sideOpen = () => {
    setSidebar(!sidebar);
  }

  // Lang
  let langSwitcher = () => {
    lang.setLanguage(!lang.language);
  }

  // Scroll position
  useEffect(() => {
    let themer = () => {
      if(window.pageYOffset >= 150){
        header.current.classList.add(Styles.extra);
      }
      else{
        header.current.classList.remove(Styles.extra);
      }
    }
    window.addEventListener("scroll", themer);
    return () => window.removeEventListener("scroll", themer);
  },[]);

  // Home link
  // useEffect(() => {
  //   let homer = () => {
  //     window.scrollTo(0,0);
  //   }
  //   logo.current.addEventListener("click", homer);
  //   return () => logo.current.removeEventListener("click", homer);
  // },[]);

  const [products_nav, setProd] = useState(false);
  const openProducts = () => setProd(true);
  const closeProducts = () => setProd(false);

  const totop = () => {
    window.scrollTo(0,0);
  }

  const [sideprod, setsp] = useState(false);

  return(
    <header>
      <div onClick={totop} className={Styles.toTop}  id="totop">
      <FaChevronUp/>
      </div>
      
      <div className={Styles.header} ref={header}>
        
        <button className={Styles.lang} onClick={langSwitcher}>
          {lang.language ? `E` : `த`}
        </button>
        <img src={Logo} alt="Batter House" className={Styles.logo} ref={logo}/>
        <div className={Styles.navLinks}>
          <ProductsNav open={products_nav} onMouseOut={closeProducts} onMouseOver={openProducts}/>
          <div  className={Styles.links}>
              <div><li onMouseOver={closeProducts} onFocus={closeProducts}><Link to="/" onClick={homer}>{contents.home}</Link></li>
                <li onMouseOver={closeProducts} onFocus={closeProducts}><Link to="/vision">{contents.vision}</Link></li>
                <li onMouseOver={closeProducts} onFocus={closeProducts}><Link to="/about">{contents.about}</Link></li>
                <li onMouseOver={closeProducts} onFocus={closeProducts}><Link to="/feedback">{contents.feedback}</Link></li>
              </div>
              <div>
              <li className={Styles.productLink} onClick={closeProducts} onMouseOver={openProducts} >
                <Link to="/#products">{contents.products} ↓</Link>
              </li>
              <li onMouseOver={closeProducts}><Link to="/news">News</Link></li>
              <li onMouseOver={closeProducts}><Link to="/#cookbook">{contents.cookbook}</Link></li>
              <li><Link to="/newAmbassador">{contents.ambassador}</Link></li>
              
              </div>
          </div>
        </div>
      </div>
      <div className={sidebar ? Styles.hamE : Styles.ham} onClick={sideOpen}>
        <img src={sidebar ? Hamx : Ham} alt="Menu"/>
      </div>
      
      <CSSTransition
        in={sidebar}
        timeout={300}
        classNames={{
          enterDone: Styles.sidebarEnter
        }}
        >
          <div className={Styles.sidebar}>
            <li><Link className={Styles.silink} onClick={() => setSidebar(false)} to="/" onClick={homer}>{contents.home}</Link></li>
            <li>
              <Link className={Styles.silink} onClick={() => setsp(!sideprod)} to="/#products">{contents.products}</Link>
              <ProductsSide open={sideprod} setSidebar={setSidebar}/>
            </li>
            <li><Link className={Styles.silink} onClick={() => setSidebar(false)} to="/vision">{contents.vision}</Link></li>
            <li><Link className={Styles.silink} onClick={() => setSidebar(false)} to="/about">{contents.about}</Link></li>
            <li><Link className={Styles.silink} onClick={() => setSidebar(false)} to="/#cookbook">{contents.cookbook}</Link></li>
            <li><Link className={Styles.silink} onClick={() => setSidebar(false)} to="/feedback">{contents.feedback}</Link></li>
            <li><Link to="/newAmbassador">{contents.ambassador}</Link></li>
            <li><Link className={Styles.silink} onClick={() => setSidebar(false)} to="/#contacts">{contents.contacts}</Link></li>
          </div>
      </CSSTransition>
    </header>
  );
}

export default Header;
