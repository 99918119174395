import React, {
    createContext,
    useState,
    useEffect
} from 'react';

const NavContext = createContext(null);

export const NavContextProvider = ({children}) => {
    const [landing,setlanding] = useState(0);
    const [about,setabout] = useState(0);
    const [cookbook,setcookbook] = useState(0);
    const [products,setproducts] = useState(0);
    const [events,setevents] = useState(0);
    const [ambassador,setambassador] = useState(0);
    const [footer,setfooter] = useState(0);

    useEffect(() => {
        setlanding(0);
    },[]);
    
    return(
        <NavContext.Provider value={{
                    landing,
                    about,
                    products,
                    cookbook,
                    events,
                    ambassador,
                    footer,
                    setlanding,
                    setabout,
                    setproducts,
                    setcookbook,
                    setevents,
                    setambassador,
                    setfooter
        }}>
            {children}
        </NavContext.Provider>
    );
}

export default NavContext;